import React, { useState, useEffect } from "react"
import { Link, graphql, PageProps } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import styled from "styled-components"
import MenuItem, {
  StyledTd,
  StyledRow,
  StyledDiv,
  toWbr,
  StyledAnchor,
} from "../components/landing/MenuItem"

type Data = {
  allInfoJson: {
    edges: {
      node: Artist
    }[]
  }
}

type Artist = {
  name: string
  title: string
  unicode: string
}

const compare = (byName: boolean, ascending: boolean) => (
  a: Artist,
  b: Artist
) => {
  const ans = byName ? a.name < b.name : a.title < b.title
  const temp = ans ? -1 : 1
  return temp * (ascending ? 1 : -1)
}

const LineBreak = () => (
  <tr>
    <td className="sm linebreak">
      <br />
    </td>
  </tr>
)

const IndexPage = ({ data }: PageProps<Data>) => {
  const [byName, setByName] = useState(true)
  const [nameAscending, setNameAscending] = useState(true)
  const [artAscending, setArtAscending] = useState(true)
  const [localData, setLocalData] = useState<Artist[]>(
    data.allInfoJson.edges.map(({ node }) => ({
      ...node,
    }))
  )
  useEffect(() => {
    setLocalData(localData =>
      [...localData].sort(
        compare(byName, byName ? nameAscending : artAscending)
      )
    )
  }, [byName, nameAscending, artAscending])

  return (
    <Layout>
      <SEO title="Home" />

      <table
        cellPadding="0"
        cellSpacing="0"
        style={{ width: "100%", tableLayout: "fixed" }}
      >
        <tbody>
          <MenuItem
            index={{ payload: "1" }}
            title={"서문"}
            body={
              "네오-\u200B트라이브\u200B2020: \u200B사회가 잠시 \u200B멀어졌을 때"
            }
            linkTo="/about"
          />
          <LineBreak />
          <tr>
            <StyledTd width={0.1}></StyledTd>
            <StyledTd width={0.3}>
              <StyledAnchor
                href="#"
                onClick={() => {
                  setByName(true)
                  setNameAscending(state => (byName ? !state : state))
                }}
                underline={byName}
                className="sort-name sm-mobile"
              >
                이름순 {nameAscending ? "↓" : "↑"}
              </StyledAnchor>
            </StyledTd>
            <StyledTd width={0.6}>
              <StyledAnchor
                href="#"
                onClick={() => {
                  setByName(false)
                  setArtAscending(state => (!byName ? !state : state))
                }}
                underline={!byName}
                desktopOnly
                className="sort-artwork sm-mobile"
              >
                작품명순 {artAscending ? "↓" : "↑"}
              </StyledAnchor>
            </StyledTd>
          </tr>
          {localData.map((artist, ind) => {
            const linkTo = "/" + artist.name.replace(/[\u200b\s]/g, "")
            return (
              <MenuItem
                index={{ payload: artist.unicode, class: "symbol" }}
                linkTo={linkTo}
                title={artist.name}
                body={artist.title}
                center
                key={ind}
              />
            )
          })}
          <LineBreak />
          <MenuItem
            index={{ payload: "2" }}
            title={"네오-믹스"}
            body=""
            linkTo="/neomix"
          />
          <MenuItem
            index={{ payload: "3" }}
            title={"평론의 \u200B글"}
            body={
              "네오-트라이브\u200B2020: \u200B그래도 \u200B다시 만나고 \u200B싶을 때"
            }
            linkTo="/review"
          />
          <MenuItem
            index={{ payload: "4" }}
            title={"큐레이터 토크"}
            body=""
            linkTo="/talk"
          />
          <MenuItem
            index={{ payload: "5" }}
            title={"전시 전경"}
            body=""
            linkTo="/overview"
          />
          <MenuItem
            index={{ payload: "6" }}
            title={"크레딧"}
            body=""
            linkTo="/credits"
          />
        </tbody>
      </table>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  {
    allInfoJson {
      edges {
        node {
          name
          title
          unicode
        }
      }
    }
  }
`
